a {
    text-decoration: none;
}

/* Text editor styles */
.ql-short-description .ql-container{
    height: 10em;
}

.ql-description .ql-container{
    height: 18em;
}